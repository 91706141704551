import { type RouteRecordRaw, type RouteLocationNormalized } from 'vue-router';

import { syncWithStorage } from '@exchange/helpers/persistance-helper';
import { capIsNativePlatform } from '@exchange/libs/utils/capacitor/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import { considerRedirectToMaintenance, shouldRedirectToMaintenance } from './maintenance-redirect';

const PageWithSideBarInternalNavigation = () => import('@exchange/layouts/PageWithSideBarInternalNavigation.vue');

declare module 'vue-router' {
  interface RouteMeta {
    isPublic: boolean;
    level?: number;
    routeAnimationDisabled?: boolean;
  }
}

const webRoutes: Array<RouteRecordRaw> = [
  {
    /** web route only */
    path: '/web-auth-callback',
    name: 'auth.callback.web',
    component: () => import(/* webpackChunkName: "chunk-static-pages" */ '@exchange/libs/auth/ui/src/lib/AuthCallback.vue'),
    meta: {
      isPublic: true,
    },
  },
  // maintenance
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import(/* webpackChunkName: "chunk-static-pages" */ '@exchange/static-pages/MaintenancePage.vue'),
    meta: {
      isPublic: true,
    },
    beforeEnter(_to, _from, next) {
      shouldRedirectToMaintenance().then((shouldRedirect) => {
        if (shouldRedirect) {
          next();
        } else {
          next('/');
        }
      });
    },
  },
  // mobile-app
  {
    path: '/mobile-app',
    name: 'mobile-app',
    component: () => import(/* webpackChunkName: "chunk-static-pages" */ '@exchange/static-pages/MobileApp.vue'),
    meta: {
      isPublic: true,
    },
  },
  // 404
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "chunk-static-pages" */ '@exchange/static-pages/Container404Page.vue'),
    meta: {
      isPublic: true,
    },
    beforeEnter(_to, from, next) {
      logger.log(`Go to 404 redirected from ${from.redirectedFrom} to ${from.fullPath}`);
      considerRedirectToMaintenance(next);
    },
  },
  // Login Timeout
  {
    path: '/login-error',
    name: 'LoginError',
    component: () => import(/* webpackChunkName: "chunk-static-pages" */ '@exchange/static-pages/LoginError.vue'),
    meta: {
      isPublic: true,
    },
    beforeEnter(_to, _from, next) {
      considerRedirectToMaintenance(next);
    },
  },
  // account stuff -- legacy support
  {
    path: '/account',
    name: 'account',
    redirect: () => ({
      name: 'trading-accounts',
    }),

    children: [
      {
        path: 'login',
        name: 'account.login',
        meta: {
          isPublic: false,
          level: 2,
        },
        redirect: '/',
        beforeEnter(_to, _from, next) {
          next('/');
        },
      },
      /** workaround to redirect api keys confirmation/cancellation urls */
      {
        path: 'api/keys',
        name: 'account.api',
        redirect: (to) => ({
          hash: to.hash,
          params: to.params,
          query: to.query,
          name: 'trading-accounts.api',
        }),
      },
    ],
  },
  // ot trading accounts
  {
    path: '/trading-accounts/:id?',
    name: 'trading-accounts',
    components: {
      default: () => import(/* webpackChunkName: "chunk-trading-accounts" */ '@exchange/libs/trading-accounts/ui/src/lib/AccountsView.vue'),
    },
    meta: {
      isPublic: false,
      level: 2,
    },
    props: true,
    beforeEnter(_to, _from, next) {
      considerRedirectToMaintenance(next);
    },
    children: [
      {
        path: 'balances',
        name: 'trading-accounts.balances',
        components: {
          default: () => import(/* webpackChunkName: "chunk-balances" */ '@exchange/libs/balances/ui/src/lib/BalancesContainer.vue'),
        },
        meta: {
          isPublic: false,
          level: 3,
        },
        props: true,
      },
      {
        path: 'orders/:tab?',
        name: 'trading-accounts.orders',
        components: {
          default: () => import(/* webpackChunkName: "chunk-order-history" */ '@exchange/libs/order/order-history/ui/src/lib/OrderHistory.vue'),
        },
        meta: {
          isPublic: false,
          level: 3,
        },
        props: true,
      },
      {
        path: 'orders/:orderId',
        name: 'trading-accounts.order',
        components: {
          default: () => import(/* webpackChunkName: "chunk-order-history" */ '@exchange/libs/order/order-history/ui/src/lib/OrderHistorySingle.vue'),
        },
        meta: {
          isPublic: false,
          level: 3,
        },
        props: true,
      },
      {
        path: 'trades',
        name: 'trading-accounts.trades',
        components: {
          default: () => import(/* webpackChunkName: "chunk-trades" */ '@exchange/libs/trade/trade-history/ui/src/lib/TradesHistoryContainer.vue'),
        },
        meta: {
          isPublic: false,
          level: 3,
        },
        props: true,
      },
      {
        path: 'transactions/:tab?',
        name: 'trading-accounts.transactions',
        components: {
          default: () => import(/* webpackChunkName: "chunk-transactions" */ '@exchange/libs/transactions/history/src/lib/TransactionHistoryContainer.vue'),
        },
        meta: {
          isPublic: false,
          level: 3,
        },
        props: true,
      },
      {
        path: 'api',
        name: 'trading-accounts.api',
        components: {
          default: () => import(/* webpackChunkName: "chunk-api-keys" */ '@exchange/libs/api-keys/ui/src/lib/ApiKeysContainer.vue'),
        },
        meta: {
          isPublic: false,
          level: 3,
        },
        props: true,
      },
    ],
  },
  // ot settings
  {
    path: '/settings',
    component: PageWithSideBarInternalNavigation,
    beforeEnter(_to, _from, next) {
      considerRedirectToMaintenance(next);
    },
    children: [
      {
        path: '',
        name: 'settings',
        components: {
          default: () => import(/* webpackChunkName: "chunk-settings" */ '@exchange/libs/settings/ui/src/lib/SettingsView.vue'),
        },
        props: true,
        meta: {
          isPublic: false,
          level: 2,
        },
        redirect: () => ({
          name: 'settings.general',
        }),
        children: [
          {
            path: 'general',
            name: 'settings.general',
            components: {
              default: () => import(/* webpackChunkName: "chunk-settings" */ '@exchange/libs/settings/ui/src/lib/UserSettings.vue'),
            },
            meta: {
              isPublic: false,
              level: 3,
            },
          },
          {
            path: 'banks',
            name: 'settings.banks',
            components: {
              default: () => import('@exchange/libs/transactions/funds/ui/src/funds-accounts/FundsAccountsContainer.vue'),
            },
            meta: {
              isPublic: false,
              level: 3,
            },
          },
        ],
      },
    ],
  },
  // ot fee levels
  {
    path: '/fee-levels',
    component: PageWithSideBarInternalNavigation,
    beforeEnter(_to, _from, next) {
      considerRedirectToMaintenance(next);
    },
    children: [
      {
        path: '',
        name: 'fee-levels',
        components: {
          default: () => import(/* webpackChunkName: "chunk-levels" */ '@exchange/libs/fees/ui/src/lib/FeeLevelsContainer.vue'),
        },
        props: true,
        meta: {
          isPublic: false,
          level: 2,
        },
      },
    ],
  },
  // ot promotions
  {
    path: '/promotions',
    component: PageWithSideBarInternalNavigation,
    beforeEnter(_to, _from, next) {
      considerRedirectToMaintenance(next);
    },
    children: [
      {
        path: '',
        name: 'promotions',
        components: {
          default: () => import(/* webpackChunkName: "chunk-partners" */ '@exchange/libs/partners/ui/src/lib/PromotionsView.vue'),
        },
        children: [
          {
            path: '',
            name: 'promotions.list',
            components: {
              default: () => import(/* webpackChunkName: "chunk-partners" */ '@exchange/libs/partners/ui/src/lib/PartnersList.vue'),
            },
            props: true,
            meta: {
              isPublic: false,
              level: 2,
            },
          },
          {
            path: ':name',
            name: 'promotions.chooser',
            components: {
              default: () => import(/* webpackChunkName: "chunk-partners" */ '@exchange/libs/partners/ui/src/lib/Partner.vue'),
            },
            props: true,
            meta: {
              isPublic: false,
              level: 3,
            },
          },
        ],
      },
    ],
  },
  // ot exports
  {
    path: '/exports',
    component: PageWithSideBarInternalNavigation,
    beforeEnter(_to, _from, next) {
      considerRedirectToMaintenance(next);
    },
    children: [
      {
        path: '',
        name: 'exports',
        components: {
          default: () => import(/* webpackChunkName: "chunk-exports" */ '@exchange/libs/exports/ui/src/lib/ExportsContainer.vue'),
        },
        props: true,
        meta: {
          isPublic: false,
          level: 2,
        },
      },
    ],
  },
  // exchange
  {
    path: '/',
    component: () => import('@exchange/layouts/trading/TradingLayout.vue'),
    props: true,
    beforeEnter(_to, _from, next) {
      considerRedirectToMaintenance(next);
    },
    children: [
      {
        path: '',
        name: 'home',
        redirect: (to) => {
          if (to.hash) {
            return {
              name: 'exchange',
            };
          }

          return {
            name: 'portfolio',
          };
        },
      },
      {
        path: '/exchange',
        name: 'exchange',
        redirect: (to) => ({
          name: 'spot',
          hash: to.hash,
          params: {
            marketId: syncWithStorage(CONSTANTS.SELECTED_MARKET, CONSTANTS.DEFAULT_MARKET_ID),
          },
          query: to.query,
        }),
      },
      {
        path: ':marketId',
        name: 'spot',
        components: {
          default: () => import('@exchange/layouts/trading/SpotExchangeContainer.vue'),
        },
        beforeEnter: (to) => {
          let marketId = to.params?.marketId;

          if (!marketId) {
            marketId = syncWithStorage(CONSTANTS.SELECTED_MARKET, CONSTANTS.DEFAULT_MARKET_ID);
            return { name: 'spot', params: { marketId } };
          }

          return true;
        },
        props: true,
        meta: {
          isPublic: true,
          level: 1,
        },
      },
      {
        path: 'trade',
        name: 'portfolio',
        components: {
          default: () => import('@exchange/libs/portfolio/ui/src/lib/PortfolioPage.vue'),
        },
        props: true,
        meta: {
          isPublic: true,
          level: 1,
        },
      },
      {
        path: 'futures/:marketId?',
        name: 'futures',
        components: {
          default: () => import('@exchange/libs/futures/ui/src/lib/FuturesPage.vue'),
        },
        beforeEnter: (to) => {
          let marketId = to.params?.marketId;

          if (!marketId) {
            marketId = syncWithStorage(CONSTANTS.SELECTED_MARKET_FUTURES, CONSTANTS.DEFAULT_MARKET_ID_FUTURES);
            return { name: 'futures', params: { marketId } };
          }

          return true;
        },
        props: true,
        meta: {
          isPublic: true,
          level: 1,
        },
      },
      // ot mobile accounts
      {
        path: 'mobile-accounts',
        name: 'mobile-accounts',
        component: () => import(/* webpackChunkName: "chunk-trading-accounts" */ '@exchange/libs/trading-accounts/ui/src/lib/AccountListContainer.vue'),
        meta: {
          isPublic: true,
          level: 1,
        },
      },
      // ot mobile profile
      {
        path: 'mobile-profile',
        name: 'mobile-profile',
        component: () => import(/* webpackChunkName: "chunk-profile" */ '@exchange/libs/account/ui/src/libs/ProfileContainer.vue'),
        meta: {
          isPublic: true,
          level: 1,
        },
      },
      // ot mobile markets
      {
        path: 'mobile-markets',
        name: 'mobile-markets',
        component: () => import(/* webpackChunkName: "chunk-profile" */ '@exchange/libs/market/ui/src/lib/MarketsContainer.vue'),
        meta: {
          isPublic: true,
          level: 1,
        },
      },
    ],
  },
  {
    // whatever unknown route -> appropriate 404 not found !Always leave this as last one!
    path: '/:pathMatch(.*)*',
    redirect: '/404',
    beforeEnter(_to, _from, next) {
      considerRedirectToMaintenance(next);
    },
  },
];

const nativeRoutes: Array<RouteRecordRaw> = [
  // Biometric auth
  {
    path: '/biometric',
    name: 'biometric',
    component: () => import(/* webpackChunkName: "chunk-native-biometric" */ '@exchange/layouts/NativeBiometricRenderer.vue'),
    redirect: (to) => ({
      hash: to.hash,
      params: to.params,
      query: to.query,
      name: 'biometric.unlock',
    }),

    children: [
      {
        path: 'unlock',
        name: 'biometric.unlock',
        components: {
          default: () => import(/* webpackChunkName: "chunk-native-biometric" */ '@exchange/libs/auth/ui/src/lib/LoginBiometric.vue'),
        },
        meta: {
          isPublic: true,
          routeAnimationDisabled: true,
        },
        beforeEnter(_to, _from, next) {
          considerRedirectToMaintenance(next);
        },
      },
    ],
  },
];

interface RouteLike {
  path: string;
}

export const getNoAuthRequiredForRoute = (route: RouteLocationNormalized) => route.matched.some((record) => record.meta.isPublic);
export const checkIsCurrentBiometricRoute = () => window.location.href.includes('/biometric/');
export const checkIsCurrentAccountRouteOT = () =>
  webRoutes.reduce((acc, c) => {
    const pathToCompare = c.path.split('/:')[0];

    if (!pathToCompare || pathToCompare === '' || pathToCompare === '/') {
      return acc;
    }

    return window.location.pathname.startsWith(pathToCompare) || acc;
  }, false);
export const checkIsCurrentAccountRoute = () => checkIsCurrentAccountRouteOT() || window.location.search !== '';
export const checkIsCurrentEOTCRoute = () => window.location.pathname.startsWith('/trade');
export const checkIsNativeAuthCallbackRoute = (r: RouteLike) => r.path.startsWith('/auth-callback');
export const checkIsWebAuthCallbackRoute = (r: RouteLike) => r.path.startsWith('/web-auth-callback');
export const checkIsBiometricRoute = (r: RouteLike) => r.path.includes('/biometric/');
const getRoutes = () => {
  let appRoutes = webRoutes;

  if (capIsNativePlatform()) {
    appRoutes = nativeRoutes.concat(appRoutes);
    appRoutes = appRoutes.filter((r) => r.name !== 'auth.callback.web');
  }

  return appRoutes;
};

export default getRoutes;
